<template>
  <div>
    <div class="d-flex align-items-center">
      <div class="flex-grow-1 mr-2">
        <b-form-group
          label-class="p-0"
          :label="translations.assignee">
          <b-select
            v-model="form.assignee"
            :options="CLAIM_ASSIGNEES"/>
        </b-form-group>
      </div>
      <div class="flex-grow-1 mr-2">
        <b-form-group
          label-class="p-0"
          :label="translations.status">
          <b-select
            v-model="form.status"
            :options="statusOptions"/>
        </b-form-group>
      </div>
      <div class="flex-grow-1 mr-2">
        <b-form-group
          label-class="p-0"
          :label="translations.reason">
          <b-select
            v-model="form.reason"
            :options="reasonOptions"/>
        </b-form-group>
      </div>
      <div class="flex-grow-1 mr-2">
        <b-form-group
          label-class="p-0"
          :label="translations.next_action">
          <b-select
            v-model="form.next_action"
            :options="nextActionOptions"/>
        </b-form-group>
      </div>
      <div class="flex-grow-1 mr-2">
        <b-form-group
          label-class="p-0"
          :label="translations.next_action_due_date">
          <VueCtkDateTimePicker format="YYYY-MM-DD"
                                :min-date="minDateAvailable"
                                :label="translations.next_action_due_date_placeholder"
                                formatted="ll"
                                :no-button-now="true"
                                :only-date="true"
                                v-model="form.next_action_due_date"/>
        </b-form-group>
      </div>
      <div class="justify-content-end">
        <b-button
          variant="primary"
          :disabled="saving || !details.id"
          @click="onSave">
          {{ translations.save }}
        </b-button>
      </div>
    </div>
    <hr>
    <b-row>
      <b-col cols="3">
        <b>{{ translations.account_name }}</b>
        <p>{{ details.claim_follow_up_task.claim_account.account_name || '-' }}</p>
      </b-col>
      <b-col cols="3">
        <b>{{ translations.payer_external_id }}</b>
        <p>{{ details.claim_follow_up_task.claim_account.payer_external_id || '-' }}</p>
      </b-col>
      <b-col cols="3">
        <b>{{ translations.task_id }}</b>
        <p>{{ details.id }}</p>
      </b-col>
      <b-col cols="3">
        <b>{{ translations.created_date }}</b>
        <p> {{ formatDate(details.created_at) || '-' }} </p>
      </b-col>
      <b-col cols="3">
        <b>{{ translations.approved_date }}</b>
        <p>{{ formatDate(details.approved_date) || '-' }}</p>
      </b-col>
      <b-col cols="3">
        <b>{{ translations.claim_follow_up_task_id }}</b>
        <p>
          <router-link :to="`/finance-operations/claim-follow-up-tasks/${details.claim_follow_up_task.id}`"
            target="_blank"
            size="sm">{{ details.claim_follow_up_task.id }}</router-link>
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import translations from '@/translations';
import { formatDate } from '@/helpers/finance';
import { CLAIM_ASSIGNEES } from '@/constants/finance';
import { CLAIM_WRITE_OFF_TASK_STATUS, CLAIM_WRITE_OFF_TASK_NEXT_ACTIONS, CLAIM_WRITE_OFF_TASK_REASONS } from '@/constants/financeOperations';
import moment from 'moment/moment';
import service from '@/services/finance-service';
import { parseResponseError } from '@/http/parsers/parse_response';
import { cloneDeep } from 'lodash';

export default {
  name: 'DetailsSectionTaskDetails',
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      CLAIM_ASSIGNEES,
      translations: translations.finance_operations.claim_write_off_tasks.details.details_section_task_details,
      translationsFullPage: translations.finance_operations.claim_write_off_tasks.details,
      saving: false,
      form: {
        assignee: this.details.assignee,
        status: this.details.status,
        reason: this.details.reason,
        next_action: this.details.next_action,
        next_action_due_date: this.details.next_action_due_date,
      },
    };
  },
  watch: {
    details: {
      handler() {
        this.form.assignee = this.details.assignee;
        this.form.status = this.details.status;
        this.form.reason = this.details.reason;
        this.form.next_action = this.details.next_action;
        this.form.next_action_due_date = this.details.next_action_due_date;
      },
      deep: true,
    },
  },
  computed: {
    minDateAvailable() {
      return moment().utc().format('YYYY-MM-DD');
    },
    statusOptions() {
      const ret = [];
      Object.keys(CLAIM_WRITE_OFF_TASK_STATUS).forEach(key => {
        ret.push({ value: key, text: CLAIM_WRITE_OFF_TASK_STATUS[key] });
      });

      return ret;
    },
    reasonOptions() {
      const ret = [ { value: '', text: '' } ];
      Object.keys(CLAIM_WRITE_OFF_TASK_REASONS).forEach(key => {
        ret.push({ value: key, text: CLAIM_WRITE_OFF_TASK_REASONS[key] });
      });

      return ret;
    },
    nextActionOptions() {
      const ret = [];
      Object.keys(CLAIM_WRITE_OFF_TASK_NEXT_ACTIONS).forEach(key => {
        ret.push({ value: key, text: CLAIM_WRITE_OFF_TASK_NEXT_ACTIONS[key] });
      });

      return ret;
    },
  },
  methods: {
    formatDate,
    async onSave() {
      try {
        this.saving = true;

        const payload = cloneDeep(this.form);
        if (payload.next_action_due_date) {
          payload.next_action_due_date = moment(payload.next_action_due_date).format('YYYY-MM-DD');
        }

        const updated = await service.updateClaimWriteOffTask(this.details.id, payload);
        this.$emit('updated', updated);
        this.$noty.success(this.translationsFullPage.success.save);
      } catch (err) {
        this.$noty.error(`${this.translationsFullPage.errors.save}: ${parseResponseError(err)}`);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
