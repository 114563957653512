import { render, staticRenderFns } from "./detailsSectionBox.vue?vue&type=template&id=22fb4b2c"
import script from "./detailsSectionBox.vue?vue&type=script&lang=js"
export * from "./detailsSectionBox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports