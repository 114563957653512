<template>
  <div>
    <b-row>
      <b-col cols="3">
        <b>{{ translations.program_id }}</b>
        <p>{{ details.claim_follow_up_task.claim.program_id || '-' }}</p>
      </b-col>
      <b-col cols="3">
        <b>{{ translations.billed_amount }}</b>
        <p>{{ details.billed_amount || '-' }}</p>
      </b-col>
      <b-col cols="3">
        <b>{{ translations.paid_amount }}</b>
        <p>{{ details.paid_amount || '-' }}</p>
      </b-col>
      <b-col cols="3">
        <b>{{ translations.write_off_amount }}</b>
        <p>{{ details.amount || '-' }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import translations from '@/translations';

export default {
  name: 'DetailsSectionFinanceInformation',
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      translations: translations.finance_operations.claim_write_off_tasks.details.details_section_finance_information,
    };
  },
};
</script>
